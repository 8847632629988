import React, { useCallback, useState, useEffect } from 'react';
import { Buffer } from 'buffer'
import { getChainId, getContractAddress, parseWallet, getEnabledTokens } from '../config';

import axios from 'axios';
import {
  MsgExecuteContract,
} from "@delphi-labs/shuttle";
import { useCosmWasmSigningClient, useExecuteContract } from 'graz';
import { GasPrice } from '@cosmjs/stargate';
import { mapError } from '../lib/errors';

function Tx(props) {

  const workflowParams = props.workflowParams;

  const [txResult, setTxResult] = useState(null);
  const [txLink, setTxLink] = useState(false);
  const [success, setSuccess] = useState(null);
  const [tryAgain, setTryAgain] = useState(null);

  const connectedWallet = props.account;

  const { data: signingClient } = useCosmWasmSigningClient({
    opts: {
      gasPrice: GasPrice.fromString("0.012untrn"),
    }
  })

function parseSigningClientErrors(e){
  let s = e.toString();
  if (s.includes("Tx not authorized:")){
    return "Burn transaction was not whitelisted!";
  } else if (s.includes("Error: Transaction declined")){
    return "User declined transaction.";
  }
  else return s;
}


  const { executeContract } = useExecuteContract({
    contractAddress: getContractAddress(parseWallet(connectedWallet).network),
    onSuccess: (result) => {
      console.log(result);
      console.log("Broadcast result", { hash: result.transactionHash });
      setTxResult({ hash: result.transactionHash });
      props.setModalMessage(["fire"]);
      updateTx(parseWallet(connectedWallet).network, { hash: result.transactionHash });
    },
    onError: (error,data) => {
      if (data.signingClient){
        console.log(data);
        console.log(error);
        props.setModalMessage([]);
        setTxResult({ hash: "", error: mapError(error) });
        props.setErrorMessage(`Wallet response: ${mapError(error)}.`);
      } else {
        console.log("Waiting for signing client");
      }
    }
  });

  let chainID;
  if (connectedWallet) chainID = getChainId(parseWallet(connectedWallet).network);

  function updateTx(network, txResult) {
    console.log(`/api/${network}/tx?txhash=${txResult.hash}`);
    axios.get(`/api/${network}/tx?txhash=${txResult.hash}`).then((r) => {
      console.log(r);
      if (r.data.code == -1) {
        setTimeout(function () { updateTx(network, txResult) }, 1000);
      } else if (r.data.code == 0) {
        props.setShowSuccess(true);
        props.setTxhash(txResult.hash);
        window.startAnimation();
      } else {
        props.setErrorMessage("Oops! We have a problem. Developers have been notified.");
      }
    });
  }


  useEffect(() => {
    //postTx()
    if (!connectedWallet) {
      return;
    }

    if (txResult) {
      return;
    }

    try {

      let msg_workflow_params = [];
      let total_burn_amount = 0;
      let burn_denom;
      for (const w of workflowParams) {
        msg_workflow_params.push({
          id: w.workflow_id,
          burn_amount: "" + w.burn_amount,
          base_amount: "" + w.base_amount,
          denom: w.denom
        })
        total_burn_amount += w.burn_amount;
        burn_denom = w.denom;
      }

      let t = getEnabledTokens().find((t) => t.address == burn_denom);
      let funds = [{ amount: "" + total_burn_amount, denom: burn_denom }];

      //TODO: calculate real Ibc Fees
      if (t.type == 'ibc') funds.push({ amount: "200000", denom: "untrn" });

      //TODO: cw20 requires a send
      // new MsgExecuteContract(
      //   parseWallet(connectedWallet).address,
      //   tokenAddress,
      //   {
      //     send: {
      //       amount: '' + (amountToken),
      //       contract: getContractAddress(parseWallet(connectedWallet).network),
      //       msg: Buffer.from(`{ "burn_token": { "native_amount": "${returnAmount}" }}`).toString('base64')
      //     },
      //   },
      try {
        let result = executeContract({
          signingClient: signingClient,
          msg: {
            "burn": {
              workflow_params: msg_workflow_params
            }
          },
          fee: "auto",
          funds: funds,
        });
      } catch(e){
        console.log(e);
      }
    } catch (error) {
      console.log(error);
      props.setModalMessage([]);
      props.setErrorMessage("Oops! We have a problem. Developers have been notified. (1001)");
    }
  }, [signingClient]);

  return (
    <>
    </>
  );
}
export default Tx;
