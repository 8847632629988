const error_map = {
    "Tx not authorized:" : "Burn transaction was not whitelisted!",
    "Error: Transaction declined" : "User declined transaction.",
}


export function mapError(e){
    let s = e.toString();
    if (error_map[e]){
        return error_map[e];
    }
    else return s;
}