import React, { useEffect } from 'react'
import Burn from './Burn.js'
import Burnlog from './Burnlog.js'
import Modal from 'react-modal';
import { config, getVersion, parseWallet } from '../config.js'

import { checkWallet, getWallet, useAccount, useConnect, useDisconnect, WALLET_TYPES } from "graz";
import { walletConfig } from '../lib/wallet-config.js';



function Main(props) {

  const { connect, status } = useConnect();
  const { data: account, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [formData, setFormData] = React.useState({
    step: "burn",
  })
  const [connectedWallet, setConnectedWallet] = React.useState({});
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [balance, setBalance] = React.useState({});


  const { step } = formData;

  function setCurrentPage(s) {
    const { step } = formData
    setFormData({ step: s })
  }

  function openModal() {
    setIsOpen(true);
  }

  function tryConnect(id) {
    connect(id);
    //setWalletConfig(id);
    closeModal();
  }

  function tryDisconnect(p){
    let w = getWallet();
    w.disable();
    disconnect();
    setConnectedWallet({});
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }

  const project = () => {
    switch (step) {
      case "burn": return <Burn account={account} broadcast={{}} isAgreement={props.isAgreement} handleAgreement={props.handleAgreement} setCurrentPage={setCurrentPage} connectedWallet={connectedWallet} balance={balance} setBalance={setBalance} connectModal={openModal} />;
      case "burnlog": return <Burnlog setCurrentPage={setCurrentPage} account={account} />;
      default: return <Burn />;
    }
  }

  // burning effect script
  const script = document.createElement("script");
  script.src = "/assets/burn.js";
  script.id = "burnScript";
  if (document.getElementById("burnScript") == null) document.body.appendChild(script);

  //console.log(parseWallet(connectedWallet));



  return (
    <>
      {isConnected && parseWallet(account).address ? (
        <button className='connectedButton' onClick={
          (e) => tryDisconnect(e)
        }><img className="walletIcon" src='assets/images/aside.svg'></img><div>{parseWallet(account).address.slice(0, 7)}...{parseWallet(account).address.slice(parseWallet(account).address.length - 4, parseWallet(account).address.length)}</div></button>
      ) : (
        <button className='connectedButton' onClick={() => openModal()}>Connect</button>
      )}
      <table className='mainTable'>
        <tr>
          <td className='topCol'>
            <img className='logo' src='assets/images/logo.png'></img>
          </td>
          <td>
            <h2 className='logoText'>CosmoBurn</h2>
            <p className='logoSubtext'>Burn to Earn</p>
          </td>
          <td className='connectCol'>


          </td>
        </tr>
        <tr>
          <td className='mainCol' colSpan={3}>
            <main>
              {project()}
            </main>


          </td>
        </tr>

        <div className='social'>


          <div className='socialRow'>
            <div className='socialButton'>
              <a href="https://twitter.com/cosmoburn" target="_blank" rel="noopener noreferrer"><img className='twitterIcon' src='assets/images/twitter2.svg'></img></a>
            </div>
            {/* <a href="https://twitter.com/cosmoburn" target="_blank" rel="noopener noreferrer">@X</a> */}
          </div>

          <div className='socialRow'>
            <div className='socialButton'>
              <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer"><img src='assets/images/icons8-telegram-app.svg'></img></a>
            </div>
            {/* <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer">@telegram</a> */}
          </div>

          <div className='socialRow'>
            <div className='socialButton'>
              <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer"><img src='assets/images/icons8-medium-2.svg'></img></a>
            </div>
            {/* <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer">@telegram</a> */}
          </div>

        </div>

        <div className='version'>
          <p>{getVersion()}</p>
        </div>
      </table>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className='connectModalBody'
        contentLabel=""
        appElement={document.getElementById('root')}
        id="modal"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            'backdrop-filter': 'blur(5px)',
          }
        }}
      >
        <div className='modalTitle'>Connect Wallet</div>
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {WALLET_TYPES.map((name) => (
          <>
            {checkWallet(name) && walletConfig[name] ?
              <button className='connectedButton' onClick={() => tryConnect({ chainId: "neutron-1", walletType: name })} key={name}>
                <img style={{width:"18px", "margin-right":"15px", }} src={walletConfig[name].icon}></img>
                {walletConfig[name].label}</button> : null}
          </>
        ))}
        
        <img className="closeIcon" onClick={closeModal} src='assets/images/close.png'></img>
      </Modal>
    </>
  )
}

export default Main