export const walletConfig = {
    keplr : {
        icon: `/assets/images/keplr.svg`,
        label: `Keplr`
    },
    leap: {
        icon: `/assets/images/leap.svg`,
        label: `Leap`
    },
    // walletconnect: {
    //     label: `WalletConnect`,
    //     icon: `/assets/images/wc.svg`
    // },
    // wc_keplr_mobile: {
    //     icon: `/assets/images/keplr.svg`,
    //     label: `Keplr`
    // },
    // wc_leap_mobile: {
    //     icon: `/assets/images/leap.svg`,
    //     label: `Leap`
    // },
}