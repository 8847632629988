import React from 'react'
import Modal from 'react-modal';
import axios from 'axios';
import Tx from './Tx';
import TxToken from './TxToken';
import Burnlog from './Burnlog.js'
import Success from './Success.js';
import RangeSlider from "react-range-slider-input";

import { getToken,getTokenAddress, config, parseWallet, getEnabledTokens } from '../config';
import Error from './Error.js';

function Burn(props) {

    function burnLog() {
        setModalMessage(["BurnStats"])
        setShowBurnLog(true);
        openModal();
    }

    const [showBurnLog, setShowBurnLog] = React.useState(false);

    const [coin, setCoin] = React.useState({
        denom: "atom"
    });

    const [amountBurnToken, setAmountBurnToken] = React.useState(0);
    const [amountReceiveAnew, setAmountReceiveAnew] = React.useState(0);
    const [amountReceiveAcAnew, setAmountReceiveAcAnew] = React.useState(0);
    const [amountReceiveTotal, setAmountReceiveTotal] = React.useState(0);

    const [workflowParams, setWorkflowParams] = React.useState([]);

    const [percReceiveAnew, setReceivePerc] = React.useState(50);
    const [percReceiveAcAnew, setReceivePercAC] = React.useState(50);


    const [modalMessage, setModalMessage] = React.useState(["..."]);
    const [burnButton, setBurnButton] = React.useState('Burn');
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showSlider, setShowSlider] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [txhash, setTxhash] = React.useState('');

    const [tokenQuote, setTokenQuote] = React.useState({
        lastQuote: undefined,
    });

    const [tokenList, setTokenList] = React.useState({
        lastReq: undefined,
    });

    let whitelist_state = { luna: false };
    getEnabledTokens().forEach((t) => {
        whitelist_state[t] = false;
    });

    const [whitelist, setWhitelist] = React.useState(whitelist_state);

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalDisclaimerIsOpen, setDisclaimerIsOpen] = React.useState(false);
    const [isAgreement, setAgreement] = React.useState(props.isAgreement);


    function initReceiveValues(val) {
        if (!val) {
            setAmountReceiveAnew(0);
            setAmountReceiveAcAnew(0);
            setReceivePerc(50);
            setReceivePercAC(50);
            setAmountReceiveTotal(0);
        } else {
            setAmountReceiveTotal(val);
            setAmountReceiveAnew(+(val * percReceiveAnew / 100).toFixed(6));
            setAmountReceiveAcAnew(+(val * percReceiveAcAnew / 100).toFixed(6));
            setShowSlider(true);

        }

    }

    function displayReceiveMsg(msg) {
        setAmountReceiveAnew(msg);
        setAmountReceiveAcAnew("-");
    }


    function inputCleanup(e) {
        e.target.value = e.target.value.replace(/[^0-9.]/g, '');
        if (e.target.value.length > 1 && e.target.value.charAt(0) == "0" && e.target.value.charAt(1) != ".") e.target.value = e.target.value.substring(1);
        if (e.target.value.charAt(0) == ".") e.target.value = "0" + e.target.value;
    }

    function updateAmountBurnToken(burnValue) {

        if (!parseWallet(props.account)) {
            setBurnButton("Please Connect Your Wallet");
            return;
        }

        if (!tokenList.list) return;

        if (isNaN(burnValue)) {
            burnValue = 0;
            return;
        }

        setAmountBurnToken(burnValue);

        if (parseFloat(burnValue) > parseFloat(props.balance.unformatted[getTokenAddress(parseWallet(props.account).network, coin.denom)])) {
            setBurnButton(["Insufficient Funds"]);
            return;
        }

        if (coin.denom == 'ntrn' && parseFloat(burnValue) + 0.05  >= parseFloat(props.balance.unformatted[getTokenAddress(parseWallet(props.account).network, coin.denom)])) {
            setBurnButton(["Not Enough NTRN For Fees"]);
            return;
        }
        
        if (coin.denom != 'atom' && burnValue >= 0) {
            console.log("QUOTE " + coin.denom);
            quote(burnValue, coin.denom);
        } else if (burnValue >= 0) {
            if (burnValue * 1000000 > tokenList.list.max_amount_native) {
                displayReceiveMsg('Exceeds Max Amount')
            } else {
                initReceiveValues(burnValue);
            }
        }
    }

    function handleChangeBurnToken(e) {
        inputCleanup(e);
        setBurnButton('Burn');
        updateAmountBurnToken(e.target.value);
    }

    function handleChangeCoinSelect(e) {
        setCoin({ denom: e.target.value });
        setAmountBurnToken(0);
        initReceiveValues(0);
        setShowSlider(false);
    }


    function openModal() {
        setIsOpen(true);
    }

    function openDisclaimer() {
        setDisclaimerIsOpen(true);
    }

    function onAfterOpenModal() {
        //window.startAnimation();
    }

    function closeModal() {
        setIsOpen(false);
        setWhitelist({});
        updateBalance();
        setShowBurnLog(false);
        setAmountBurnToken(0);
        initReceiveValues(0);
        setShowSuccess(false);
        setShowSlider(false);
    }

    function closeDisclaimer() {
        let map = {...isAgreement};
        console.log(map);
        map['1'] = true;
        map['2'] = true;
        props.handleAgreement(map);
        setAgreement(map);
        setDisclaimerIsOpen(false);

    }

    function clickAgreement() {
        let map = {...isAgreement};
        console.log(map);
        map['1'] = !map['1'];
        setAgreement(map);
    }

    function clickAgreement2() {
        let map = {...isAgreement};
        console.log(map);
        map['2'] = !map['2'];
        setAgreement(map);
    }

    function updateBalance() {
        axios.get(`/api/${parseWallet(props.account).network}/balance?address=${parseWallet(props.account).address}`).then((r) => {
            console.log(r);
            let balanceData = {
                balance: {},
                unformatted: {}
            }

            getEnabledTokens().forEach((t) => {
                balanceData.balance[t.address] = parseFloat(r.data[t.address] / 1000000).toLocaleString(undefined, { maximumFractionDigits: 2 });
                balanceData.unformatted[t.address] = parseFloat(r.data[t.address] / 1000000);
            });

            console.log(balanceData);
            props.setBalance(balanceData);
        }).catch((e) => {
            console.log(e);
        });
    }

    function quote(amount, token) {
        if (Burn.quoteTimeout) clearTimeout(Burn.quoteTimeout);
        const page = this;
        displayReceiveMsg('getting price...');

        Burn.quoteTimeout = setTimeout(function () {
            displayReceiveMsg('getting price...');
            let t = getEnabledTokens().find((et) => et.name == token);
            axios.get(`/api/mainnet/quote?source_chain_id=${t.chain_id}&source_denom=${t.native_denom}&destination_chain_id=cosmoshub-4&destination_denom=uatom&amount_in=${amount * 1000000}`).then((r) => {
                console.log(`/api/mainnet/quote?source_chain_id=${t.chain_id}&source_denom=${t.native_denom}&destination_chain_id=cosmoshub-4&destination_denom=uatom&amount_in=${amount * 1000000}`)
                console.log(r);
                initReceiveValues(r.data.amount_out / 1000000);
            });
        }, 2000);
    }

    function handleSubmit(e) {

        console.log(typeof props.account )
        if (props.account == null) {
            setBurnButton("Please connect your wallet");
            setAmountBurnToken(0);
            initReceiveValues();
            props.connectModal();
            return;
        }

        if (amountBurnToken == null || amountBurnToken == 0 || amountBurnToken == '' || isNaN(amountBurnToken) || isNaN(amountReceiveAnew) || isNaN(amountReceiveAcAnew)) return;

        if (isNaN(props.balance.unformatted[getTokenAddress(parseWallet(props.account).network, coin.denom)]) || parseFloat(amountBurnToken) > parseFloat(props.balance.unformatted[getTokenAddress(parseWallet(props.account).network, coin.denom)])) {
            setBurnButton(["Insufficient Funds"]);
            return;
        }

        setWhitelist({});

        openModal();

        setModalMessage(["fire"]);

        let workflows = [];
        if (amountReceiveAnew > 0) workflows.push({
            burner_addr: parseWallet(props.account).address,
            base_amount: Math.floor(amountReceiveAnew * 1000000),
            denom: getTokenAddress(parseWallet(props.account).network, coin.denom),
            burn_amount: parseInt(amountBurnToken * 1000000 * (amountReceiveAnew / amountReceiveTotal)),
            workflow_id: "ANEW"
        });
        if (amountReceiveAcAnew > 0) workflows.push({
            burner_addr: parseWallet(props.account).address,
            base_amount: Math.floor(amountReceiveAcAnew * 1000000),
            denom: getTokenAddress(parseWallet(props.account).network, coin.denom),
            burn_amount: (amountBurnToken * 1000000) - parseInt(amountBurnToken * 1000000 * (amountReceiveAnew / amountReceiveTotal)),
            workflow_id: "acANEW"
        });
        setWorkflowParams(workflows);
        let payload = {
            burner_addr: parseWallet(props.account).address,
            fee_grant: false,
            workflows: workflows
        }
        console.log(workflows);
        axios.post(`/api/${parseWallet(props.account).network}/whitelist?`, payload, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((r) => {
            if (r.data.code == 0) {
                openModal();
                setModalMessage(["fire"]);
                setTimeout(function () {
                    setWhitelist({ [coin.denom]: true });
                    //closeModal();
                }, 1000);
            }
            else {
                closeModal();
                setModalMessage([]);
                setErrorMessage("Oops! We have a problem. Developers have been notified.");
            }
        }).catch((e) => {
            closeModal();
            setModalMessage([]);
            console.log(e);
            setErrorMessage(`Oops! We have a problem. ${e.response.data}.`);
            setShowSlider(false);

        });


        e.preventDefault();
    }

    function getCoinIcon(s) {
        return <img className="coinIcon" src={getEnabledTokens().find((token) => token.name == s).icon}></img>
    }

    function makeOption(s) {
        return <option value={s.name}>{s.display}</option>;
    }

    function burnAllOfIt() {
        let t = getEnabledTokens().find((et) => et.name == coin.denom);

        if (!props.balance || !props.balance.unformatted) return;
        let burnAmount = parseFloat(props.balance.unformatted[t.address]);
        if (t.name == 'ntrn') burnAmount = (parseFloat(props.balance.unformatted[t.address]) - 0.051).toFixed(6);
        if (isNaN(burnAmount) || burnAmount == 0) return;
        setBurnButton('Burn');
        updateAmountBurnToken(burnAmount);
    }

    function getTokenByName(name) {
        let r = getEnabledTokens().find((token) => token.name == name);
        return r;
    }

    function onChangePercent(e) {
        let perc = e[1];

        setReceivePercAC(perc);
        setReceivePerc(100 - perc);

        setAmountReceiveAcAnew(+(amountReceiveTotal * perc / 100).toFixed(6));
        setAmountReceiveAnew(+(amountReceiveTotal * (100 - perc) / 100).toFixed(6));
    }

    function changeReceiveAmount(e) {
        console.log(e.target.value);
        let value = isNaN(e.target.value) ? 0 : parseFloat(e.target.value);
        setAmountReceiveAnew(+parseFloat(value).toFixed(6));
        setAmountReceiveAcAnew(amountReceiveTotal - value > 0 ? +parseFloat(amountReceiveTotal - value).toFixed(6) : "-");
        if (amountReceiveTotal - value < 0 || value < 0 || isNaN(value)) {
            setBurnButton("Exceeds Max Funds Received");
        } else {
            setBurnButton("Burn");
        }
        setShowSlider(false);
    }

    function changeReceiveAmountAC(e) {
        console.log(e.target.value);
        let value = isNaN(e.target.value) ? 0 : parseFloat(e.target.value);
        setAmountReceiveAcAnew(+parseFloat(value).toFixed(6));
        setAmountReceiveAnew(amountReceiveTotal - value > 0 ? +parseFloat(amountReceiveTotal - value).toFixed(6) : "-");
        if (amountReceiveTotal - value < 0 || value < 0 || isNaN(value)) {
            setBurnButton("Exceeds Max Funds Received");
        } else {
            setBurnButton("Burn");
        }
    }

    React.useEffect(() => {
        if (amountReceiveAnew == null) initReceiveValues();
    });

    React.useEffect(() => {
        if (!isAgreement || !isAgreement['1'] || !isAgreement['2']) setDisclaimerIsOpen(true);
    });

    React.useEffect(() => {
        if (!tokenList.lastReq && parseWallet(props.account).network) {
            axios.get(`/api/${parseWallet(props.account).network}/wtokens`).then((r) => {
                setTokenList({ lastReq: new Date(), list: r.data });
                console.log({ lastReq: new Date(), list: r.data });
            }).catch((e) => { console.log(e); });
        }
    });

    React.useEffect(() => {
        if (!tokenQuote.lastQuote) {
            let quotes = { lastQuote: new Date() }
            setTokenQuote(quotes);
            getEnabledTokens().forEach((t) => {
                var url = `/api/mainnet/quote?destination_chain_id=${t.chain_id}&destination_denom=${t.native_denom}&source_chain_id=cosmoshub-4&source_denom=uatom&amount_in=${1000000}`;
                axios.get(url).then((r) => {
                    console.log(url);
                    console.log(r);
                    let name = t.name;
                    quotes = { ...quotes, [name]: parseFloat(r.data.amount_out / 1000000).toLocaleString(undefined, { maximumFractionDigits: 4 }) };
                    setTokenQuote(quotes);
                    console.log(quotes);
                }).catch((e) => { console.log(e); });
            })
        }
    });

    React.useEffect(() => {
        if (parseWallet(props.account)) {
            setBurnButton("Burn");
            updateBalance();
        }
 

    }, [props.account]);

    return (
        <div className='contentWrapper'>
            <div className="content">
                <p className='topText2'>Burn Tokens. Reduce Supply. Earn Rewards.</p>
                <p className='topText'>Burn 1 ATOM for 1 ANEW or 1 acANEW. Burn other tokens at their price relative to ATOM. 80% of tokens are burned forever and 20% are sent to tokenholders. ANEW holders earn burned tokens directly. acANEW holders earn autocompounding acANEW.</p>

                <div className="selector">
                    <table>
                        <tr>
                            <td colSpan={2}><p>Burn</p> </td>
                            <td className='asideCol'><div className='aside' onClick={burnAllOfIt}><img className='asideImg' src='assets/images/aside.svg'></img>{!props.balance.unformatted || isNaN(props.balance.unformatted[getTokenByName(coin.denom).address]) ? '0.0' : coin.denom == 'ntrn' ? (parseFloat(props.balance.balance[getTokenByName(coin.denom).address])).toFixed(2) : props.balance.balance[getTokenByName(coin.denom).address]} {coin.denom.toUpperCase()}</div></td>
                        </tr>
                        <tr>
                            <td className='logoCol'>{getCoinIcon(coin.denom)}</td>
                            <td className='selectorCol'>
                                <select name='coinSelect' className='coinSelector' onChange={handleChangeCoinSelect}>
                                    {getEnabledTokens().map(makeOption)}
                                </select>
                            </td>
                            <td>
                                <div className='amountInput'><input autoComplete='false' name="burnAmount" value={amountBurnToken == "0" ? '' : amountBurnToken} onClick={inputCleanup} onChange={handleChangeBurnToken} type="text" placeholder='0'></input></div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} className='asideCol'><div className='fees'>+fees: {parseFloat(getToken(parseWallet(props.account).network, coin.denom).fees/1000000).toFixed(2)} NTRN</div></td>
                        </tr>
                    </table>
                </div>
                <div className='spacer'><img src='assets/images/down.svg'></img></div>
                <div className="selector">
                    <table>
                        <tr>
                            <td colSpan={3}><p>Receive {amountReceiveTotal ? ": " + parseFloat(amountReceiveTotal).toLocaleString(undefined, { maximumFractionDigits: 6 }) : ""}</p> </td>
                        </tr>
                        <tr>
                            <td className='logoCol'><img className='coinIcon' src='assets/images/anew.png'></img></td>
                            <td className='selectorCol selectorNoSelect'>
                                <div className='anewLabel'>
                                    ANEW
                                </div>
                                <div className='apyLabel'>
                                    {/* APR 112% */}
                                    &nbsp;
                                </div>
                            </td>
                            <td rowSpan={2}>
                                {showSlider ?
                                    <>
                                        <div className='rangeLabel'>&nbsp;</div>
                                        <RangeSlider onInput={onChangePercent} className="single-thumb" defaultValue={[0, 50]} />
                                        <div className='rangeLabel'>{percReceiveAnew + "% - " + percReceiveAcAnew + "%"} </div>
                                    </>
                                    : <></>}
                            </td>
                            <td>
                                <div className='amountInput'><input name="receiveAmount" value={amountReceiveAnew == '0' ? '' : amountReceiveAnew} contentEditable={true} onChange={changeReceiveAmount}
                                    placeholder='0'></input></div>
                            </td>
                        </tr>
                        <tr>
                            <td className='logoCol'><img className='coinIcon' src='assets/images/acanew.svg'></img></td>
                            <td className='selectorCol selectorNoSelect'>
                                <div className='anewLabel'>
                                    acANEW
                                </div>
                                <div className='apyLabel'>
                                    {/* APY 123% */}
                                    &nbsp;
                                </div>
                            </td>

                            <td>
                                <div className='amountInput'><input name="receiveAmountAC" value={amountReceiveAcAnew == '0' ? '' : amountReceiveAcAnew} contentEditable={true} onChange={changeReceiveAmountAC}
                                    placeholder='0'></input></div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div>
                    <p className='exchange'>1 ANEW = 1 ATOM {coin.denom != 'atom' ? " = " + tokenQuote[coin.denom] + " " + getEnabledTokens().find((t) => t.name == coin.denom).display : null}</p>
                </div>
                <div className={burnButton == 'Burn' ? 'buttons' : 'disabledButtons'}>
                    <input type="submit" value={burnButton} onClick={handleSubmit} />
                </div>
                {/* <div name="burn_ledger_link" onClick={burnLog}><p className='bottom-link'>BurnStats</p></div> */}
            </div>
            <div className='linksContainer'>
                <div ><p className='link'><a href='' target='_blank'>FAQ</a></p></div>
                <div ><p className='link' onClick={burnLog}>BurnStats</p></div>
            {/* <div ><p className='link' onClick={openDisclaimer}>Disclaimer</p></div> */}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={onAfterOpenModal}
                className={modalMessage[0] == 'fire' ? 'transparentModal' : 'modalBody'}
                contentLabel=""
                appElement={document.getElementById('root')}
                id="modal"
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.60)',
                        'backdrop-filter': 'blur(5px)',
                    }
                }}
            >
                <div className={showBurnLog ? 'burnModalText' : 'modalText'}>{modalMessage[0] == 'fire' ? <img className='fireIcon' src='assets/images/fire.gif'></img> : modalMessage[0]}
                    {modalMessage[1] ? <div className={'modalText'}>{modalMessage[1]}</div> : null}
                    {modalMessage[2] ? <div className={'modalMessageExtra'}>{modalMessage[2]}</div> : null}
                    {modalMessage[3] ? <div className={'modalMessageExtra'}>{modalMessage[3]}</div> : null}
                    {modalMessage[4] ? <div className={'modalMessageExtra'}>{modalMessage[4]}</div> : null}

                    {
                        // whitelist.luna ? <Tx close={closeModal} setErrorMessage={setErrorMessage} setModalMessage={setModalMessage} setShowSuccess={setShowSuccess} setTxhash={setTxhash} amount={amountBurnToken * 1000000} connectedWallet={props.account} /> :
                        props.account && whitelist[coin.denom] ? <Tx workflowParams={workflowParams} account={props.account} close={closeModal} setErrorMessage={setErrorMessage} setModalMessage={setModalMessage} setShowSuccess={setShowSuccess} setTxhash={setTxhash} amountToken={amountBurnToken * 1000000} tokenAddress={getTokenAddress(parseWallet(props.account).network, coin.denom)} returnAmount={Math.floor(amountReceiveAnew * 1000000)} /> : null
                    }
                    {
                        showBurnLog ? <Burnlog connectedWallet={props.account}></Burnlog> : null
                    }
                </div>
                {modalMessage[0] == 'fire' ? null : <img className="closeIcon" onClick={closeModal} src='assets/images/close.png'></img>}
            </Modal>
            {
                showSuccess ? <Success close={closeModal} network={parseWallet(props.account).network} burnedAmount={amountBurnToken} burnedDenom={coin.denom} receiveAmountAnew={amountReceiveAnew} receiveAmountAcAnew={amountReceiveAcAnew} tx={txhash}></Success> : null
            }
            {
                errorMessage ? <Error message={errorMessage} setMessage={setErrorMessage} ></Error> : null
            }
            <Modal
                isOpen={modalDisclaimerIsOpen}
                onAfterOpen={onAfterOpenModal}
                className='disclaimerModalBody'
                contentLabel=""
                appElement={document.getElementById('root')}
                id="modal"

                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.80)',
                        'backdrop-filter': 'blur(5px)',
                    }
                }}
            >
                <div className='modalTitle'>User Acknowledgment & Terms</div>
                <div>


                    <table>
                        <tr>
                            <td className='disclaimerCell'><p className='disclaimerCheck'><input type='checkbox' name='a1' checked={isAgreement && isAgreement['1']} onChange={clickAgreement}></input></p></td>
                            <td className='disclaimerCell'><p className='disclaimerText'>
                                I acknowledge that CosmoBurn is noncustodial and that I retain full control of my assets. Transactions are executed via smart contracts on a public blockchain and are irreversible. I confirm that I have reviewed the protocol, I understand the risks, and my participation is entirely voluntary.
                            </p></td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className='disclaimerCell'><p className='disclaimerCheck'><input type='checkbox' name='a2' checked={isAgreement && isAgreement['2']} onChange={clickAgreement2}></input></p></td>
                            <td className='disclaimerCell'><p className='disclaimerText'>
                                I understand that CosmoBurn’s native tokens, ANEW and acANEW, are earned by burning tokens from external chains or protocols, with no guarantees of profit. Token values and rewards depend on factors beyond the control of the protocol, which is not liable for any losses. I agree to comply with all laws in my jurisdiction governing my participation.
                            </p></td>
                        </tr>
                    </table>

                    <div className='disclaimerText'><div className={isAgreement && isAgreement['1'] && isAgreement ['2'] ? "buttons":"buttons"}>
                        <input onClick={isAgreement && isAgreement['1'] && isAgreement ['2'] ? closeDisclaimer:null} type="submit" value={!(isAgreement && isAgreement['1'] && isAgreement ['2']) ? "I Agree" : "I Agree"} />
                    </div></div>
                </div>
            </Modal>
        </div>
    )
}

Burn.quoteTimeout = null;

export default Burn