import React from 'react'
import ReactDOM from 'react-dom'
import App from './App.js'
import { GrazProvider } from "graz";
import { cosmoshub, neutron } from "./chains.js";


const WC_PROJECT_ID = "48a949832f6826ffa556e316e4b5261b";


ReactDOM.render(
  <GrazProvider
    grazOptions={{
      chains: [neutron, cosmoshub],
      walletConnect: {
        options: {
          projectId: WC_PROJECT_ID,
        },
      }
    }}
  >
    <App />
  </GrazProvider>,
  document.getElementById('root'),
);
